import PermissionManager, { PortalPermission } from "./app/PermissionManager";

export enum WIDGET_ID {
  DASHBOARD = "dashboard",
  ALLOWLIST = "allowlist",
  TICKETS = "tickets",
  OFAC = "ofac",
  USERS = "users",
  PARTIES = "parties",
  TXNS = "txns",
  TXN_DETAIL = "txnDetail",
  PARTY_SEARCH_LIST = "partySearchList",
  ACTIVITY_LIST = "activityList",
  CUSTOMER_DETAIL = "customerDetail",
  GIFTCARD_LIST = "giftCardList",
  GIFTCARD_DETAIL = "giftDetail",
  //GIFTCARD_MERCHANT = 'giftCardMerchant',
  GIFTCARD_DASHBOARD = "giftCardDashboard",
  REPORTS = "reports",
  ROLE_MANAGEMENT = "roleManagement",
  BAKKT_CARD_DEVICE_DETAILS = "bakktCardDeviceDetails",
  BAKKT_CARD_DETAILS = "bakktCardDetails",
  ACH_REPORT_DETAILS = "achReportDetails",
  INCENTIVE_PROGRAM_DETAILS = "incentiveProgramDetails",
  INSTITUTIONDENY_LIST = "institutionDenyList",
  INSTITUTIONDENY_DETAILS = "institutionDenyDetails",
  WIRE_DETAILS = "wireDetails",
  WIRES_LIST = "wiresList",
}

export default {
  widgets: [
    {
      id: WIDGET_ID.DASHBOARD,
      name: "Dashboard",
      component: () => import("./dashboard/DashboardView"),
      isPermissoned: () => {
        return PermissionManager.userAllowed([PortalPermission.GIFTCARD_VIEW, PortalPermission.PARTY_VIEW]);
      },
      required: true,
      loading: true,
    },
    {
      id: WIDGET_ID.ALLOWLIST,
      name: "Allow List",
      component: () => import("./allowlist/View"),
      isPermissoned: () => {
        return PermissionManager.userAllowed([PortalPermission.ALLOWLIST_VIEW]);
      },
      required: true,
      loading: true,
    },
    {
      id: WIDGET_ID.USERS,
      name: "Users",
      component: () => import("./users/UsersListView"),
      isVisibleInWidgetMenu: false,
      isPermissoned: () => {
        return PermissionManager.userAllowed([PortalPermission.USERS_VIEW]);
      },
      loading: true,
    },
    {
      id: WIDGET_ID.ROLE_MANAGEMENT,
      name: "Role Management",
      component: () => import("./users/RoleListView"),
      isVisibleInWidgetMenu: false,
      isPermissoned: () => {
        return PermissionManager.userAllowed([PortalPermission.USERS_VIEW]);
      },
      loading: true,
    },
    {
      id: WIDGET_ID.REPORTS,
      name: "Reports",
      component: () => import("./reports/Reports"),
      isPermissoned: () => {
        return PermissionManager.isAnyReportViewPermissioned();
      },
      required: true,
      loading: true,
    },
    {
      id: WIDGET_ID.TXN_DETAIL,
      name: "Transaction Detail",
      component: () => import("./transactions/transactionDetails"),
      isVisibleInWidgetMenu: false,
      isPermissoned: () => {
        return PermissionManager.userAllowed([PortalPermission.TRANSACTIONS_VIEW]);
      },
      loading: true,
    },
    {
      id: WIDGET_ID.ACTIVITY_LIST,
      name: "Activity List",
      component: () => import("./transactions/AssetActivityListView"),
      isVisibleInWidgetMenu: false,
      isPermissoned: () => {
        return PermissionManager.userAllowed([PortalPermission.TRANSACTIONS_VIEW]);
      },
      loading: true,
    },
    {
      id: WIDGET_ID.PARTY_SEARCH_LIST,
      name: "Consumer Search List",
      component: () => import("./parties/PartySearchListView"),
      isVisibleInWidgetMenu: false,
      isPermissoned: () => {
        return PermissionManager.userAllowed([PortalPermission.PARTY_VIEW]);
      },
      loading: true,
    },
    {
      id: WIDGET_ID.CUSTOMER_DETAIL,
      name: "Customer Detail",
      component: () => import("./parties/partyDetails"),
      isVisibleInWidgetMenu: false,
      isPermissoned: () => {
        return PermissionManager.userAllowed([PortalPermission.PARTY_VIEW]);
      },
      loading: false,
    },
    /*{
      id: WIDGET_ID.GIFTCARD_LIST,
      name: 'Gift Cards',
      component: () => import('./giftCards/GiftCardsView'),
      isVisibleInWidgetMenu: false,
      isPermissoned: () => {
        return PermissionManager.userAllowed([PortalPermission.GIFTCARD_VIEW])
      },
      loading: false,
    },
    {
      id: WIDGET_ID.GIFTCARD_DETAIL,
      name: 'Gift Card Details',
      component: () => import('./giftCards/giftCardDetails'),
      isVisibleInWidgetMenu: false,
      isPermissoned: () => {
        return PermissionManager.userAllowed([PortalPermission.GIFTCARD_VIEW])
      },
      loading: true,
    },
    {
      id: WIDGET_ID.GIFTCARD_MERCHANT,
      name: 'Merchant Details',
      component: () => import('./giftCards/giftCardMerchant'),
      isVisibleInWidgetMenu: false,
      isPermissoned: () => {
        return PermissionManager.userAllowed([PortalPermission.GIFTCARD_VIEW])
      },
      loading: true,
    },*/
    {
      id: WIDGET_ID.ACH_REPORT_DETAILS,
      name: "ACH Report Details",
      component: () => import("./reports/ACHReportDetails"),
      isVisibleInWidgetMenu: false,
      isPermissoned: () => {
        return PermissionManager.userAllowed([PortalPermission.FINANCE_VIEW]);
      },
      loading: true,
    },
    {
      id: WIDGET_ID.INCENTIVE_PROGRAM_DETAILS,
      name: "Incentive Program Details",
      component: () => import("./incentiveProgram/components/IncentiveProgramDetails"),
      isVisibleInWidgetMenu: false,
      isPermissoned: () => {
        return PermissionManager.userAllowed([PortalPermission.INCENTIVE_OFFER_VIEW]);
      },
      loading: true,
    },
    {
      id: WIDGET_ID.INSTITUTIONDENY_LIST,
      name: "Denied Institutions",
      component: () => import("./institutions/DeniedInstitutionView"),
      isPermissoned: () => {
        return PermissionManager.isUserAdminOrCompliance();
      },
      required: true,
      loading: true,
    },
    {
      id: WIDGET_ID.INSTITUTIONDENY_DETAILS,
      name: "Denied Institution Details",
      component: () => import("./institutions/DeniedInstitutionDetailsView"),
      isVisibleInWidgetMenu: false,
      isPermissoned: () => {
        return PermissionManager.isUserAdminOrCompliance();
      },
      loading: true,
    },
    // Uncomment when Backend endpoint is ready to display Wires Tab
    // {
    //   id: WIDGET_ID.WIRE_DETAILS,
    //   name: "Wire Details",
    //   component: () => import("./wires/WireProcessingDetails"),
    //   isVisibleInWidgetMenu: false,
    //   isPermissoned: () => {
    //     return PermissionManager.userAllowed([PortalPermission.PARTY_VIEW]);
    //   },
    //   loading: true,
    // },
    // {
    //   id: WIDGET_ID.WIRES_LIST,
    //   name: "Wires",
    //   component: () => import("./wires/WireSummary"),
    //   isPermissoned: () => {
    //     return PermissionManager.userAllowed([PortalPermission.PARTY_VIEW]);
    //   },
    //   required: true,
    //   loading: true,
    // },
  ],
};
